import styled from 'styled-components';




export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:20px;
    overflow: visible !important;
    margin: 0 50px;
    position:relative;
    transform: translateX(0) ;
`

export const ArrowsDiv = styled.div`
    display: flex;
    align-items: center;

    
  .icon {
    fill: #9D4EDD;
    cursor: pointer;
  }

`