import { Container } from './styles';

interface IProps {
    data: {
        id: number,
        title: string,
        image: string,
    }
}

export function Card({ data }: IProps) {
    return (
        <Container>
            <div className='card'>
                <div className='circle'><img src={data.image} alt={data.title}></img></div>
                <div className='content'>
                    <h3>{data.title}</h3>
                </div>
            </div>
        </Container>
    )
}