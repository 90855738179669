import styled from 'styled-components';

export const Container = styled.div`  
  

  h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 72px;
    line-height: 88px;
    width: 483px;
    margin-bottom: 50px;
  }

  .icon {
	fill: #9D4EDD !important;
  transition: 0.2s;
  z-index: 5;
}

  .mobile {
    display: none;
  }

  span {
    color: #9D4EDD;
  }

  .icon-div {
    height: 70px;
    width: 70px;
    transition: 0.5s;
    border-radius: 100%;
    border: 8px solid #9D4EDD;
    cursor: pointer;

    &:hover .icon {
      fill: white !important;
    }

    &:hover:before {
      height: 72px;
      width: 72px;
      background: #9D4EDD;
    }
  }

  .icon-div:before {
    content: '';
    position: absolute;
    height: 0px;
    width: 0px;
    background: #9D4EDD;
    border-radius: 100%;
    transition: 0.5s;
  }

  div {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`