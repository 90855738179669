import React from "react";
import {
  Routes,
  Route,
  useLocation
} from 'react-router-dom';
import { Home } from "../pages/Home/Home";
import { AnimatePresence } from 'framer-motion';
import { Works } from "../pages/Works/Works";
import { Skills } from "../pages/Skills/Skills";
import { Me } from "../pages/Me/Me";

export function AnimatedRoutes() {
  const location = useLocation();
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/works" element={<Works />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/me" element={<Me />} />
      </Routes>
    </AnimatePresence>
  )
}