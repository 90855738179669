import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;

    h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 88px;
    margin-bottom: 50px;
    text-align: center;
  }

  span {
    color: #9D4EDD;
  }

  .slide {
    width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .carrousel {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    text-align: center;
    font-family: 20px;
    color: #9D4EDD;
    margin-top: 11px;
    margin-bottom: 6px;
  }

  img::selection {
    color: none;
    background: none;
  }

  h2::selection {
    color: none;
    background: none;
  }

  .icon {
    fill: #9D4EDD;
    cursor: pointer;
  }

  p {
    text-align: center;
    padding: 0 50px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  img {
    border-radius: 20px;
    width: 100%;
    max-width: 700px;
  }

  .on-going {
    background: black;
    width: 100%;
    max-width: 700px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 370.66px;
  }
`

export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`