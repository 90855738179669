import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const works = [
  {
    id: 0,
    title: 'Amazônia +21',
    image: 'https://iili.io/s3k3FI.png',
    description: 'Website developed in 2021 for the Amazônia +21 intitute, a institute that promotes sustainable businesses and contributes to a sustainable economy in Amazônia '
  },
  {
    id: 3,
    title: 'Gabriela Marcolina',
    image: 'https://iili.io/snE07e.png',
    description: "Website developed in 2022 for Gabriela Marcolina's odontological clinic."
  },
  {
    id: 2,
    title: 'Marcolina e Barros',
    image: 'https://iili.io/snEJea.png',
    description: 'Website devolped in 2022 for Marcolina & Barros law office.'
  },
  {
    id: 4,
    title: 'Isabela França',
    image: require('../../assets/isabelafranca.png'),
    description: "My first website, developed in 2021 for Isabela França's marketing company."
  },
  {
    id: 5,
    title: 'Shakti Dalprem',
    image: require('../../assets/shaktidalprem.png'),
    description: 'Website site developed for Shakti Dalprem in 2021, an e-commerce for paintings and mandalas and an informative website. '
  },
  {
    id: 1,
    title: 'notis/radar',
    image: require('../../assets/radar.jpg'),
    description: 'Backend and frontend developer for a internal company service called Radar, a webapp that checks if any website is online by sending a HTTP request at a customizable timer, and checking if the status code of the response is either 200 (Online) or 500 (Offline).'
  },
]


export function Slider() {
  const [id, setId] = useState(0);

  const slide = works.find(work => work.id === id)

  function nextSlide() {
    if (id < works.length - 1) {
      setId(id + 1)
      console.log(id)
    } else {
      setId(0)
    }
  }

  function previousSlide() {
    if (id > 0) {
      setId(id - 1)
    } else {
      setId(works.length - 1)
    }
  }


  return (
    <div>
      <div className='slide'>
        <div className='carrousel'>
          <FaChevronLeft className='icon' size={34} onClick={() => previousSlide()} />
          {!slide?.image ? <div className="on-going"><h1>Soon...</h1></div> : <img src={slide?.image} alt={slide?.title} />}
          <FaChevronRight className='icon' size={34} onClick={() => nextSlide()} />
        </div>
        <h2>{slide?.title}</h2>
        <p>
          {slide?.description}
        </p>
      </div>
    </div>
  )
}