import React, { useState } from 'react';
import { Container } from './styles';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Paginator } from '../../components/Paginator/Paginator';

export function Home() {
  const navigate = useNavigate();
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 200
      }}
      animate={{
        opacity: 1,
        y: 0
      }}
      exit={{
        opacity: 0,
        y: -200
      }}
      transition={{ duration: 0.6 }}
    >
      <Paginator title='Home'/>
      <Container>
        <div>
          <h1>
            Welcome to my <span>portfolio.</span>
            </h1>
            <h2 className='mobile'>Mobile coming soon...</h2>
          <div className='icon-div' onClick={() => navigate('/works')}>
            <FaChevronDown size={51} color="#9D4EDD" className='icon' />
          </div>
        </div>
      </Container>
    </motion.div>
  );
}