import React from 'react';
import {
  BrowserRouter
} from 'react-router-dom';
import { AnimatedRoutes } from './AnimatedRoutes.routes';

export default function MainRoutes() {
  return (
    <BrowserRouter>
      <AnimatedRoutes />
    </BrowserRouter>
  )
}