import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 40px;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .circle {
    width: 10px;
    margin: 8px 0;
    height: 10px;
    border-radius: 100%; 
    background-color: #E0AAFF;
    cursor: pointer;
  }

  .s-circle {
    width: 5px;
    margin: 8px 0;
    height: 5px;
    border-radius: 100%; 
    background-color: #E0AAFF;
  }

  .active {
    width: 15px;
    height: 15px;
    border-radius: 100%;  
    background-color: #7B2CBF;
    margin: 2px 0;
    pointer-events: none;
  }

  .title {
    display: flex;
    position: relative;
  }

  h3 {
    position: absolute;
    left: 20px;
    top: -3px;
    color: #7B2CBF;
  }

`