import { Container, PageDiv } from "./styles";
import { motion } from 'framer-motion';
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Slider } from "../../components/Slider/Slider";
import { Paginator } from "../../components/Paginator/Paginator";
import { Contact } from "../../components/Contact/Contact";

export function Works() {

  const navigate = useNavigate();

  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0
      }}
      transition={{ duration: 0.6 }}
    >
      <Paginator title='Works'/>
      <PageDiv>
        <Contact />
        <FaChevronUp onClick={() => navigate('/')} style={{ marginTop: 20 }} />
        <Container>
          <div>
            <h1> Some of my <span>projects.</span> </h1>
            <Slider />
          </div>
        </Container>
        <FaChevronDown onClick={() => navigate('/skills')} />
      </PageDiv>
    </motion.div >
  )
}