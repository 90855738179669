import styled, { keyframes } from 'styled-components';
const fadeIn = keyframes`
    from{
        opacity: 0;
    } to {
        opacity: 1
    }
`
export const Container = styled.div`
min-width: 175px;
height: 231px;
animation: 0.3s ease ${fadeIn};
display: block;


.card {
    width: 100%;
    height: 100%;
    border: 1px solid #7B2CBF;
    border-radius: 0 0 25px 25px; 
    position: relative;

    .circle {   
        width: 100px;
        height: 100px;
        position: absolute;
        border-radius: 100%;
        top: -50px;
        right: 20%;
        border: 1px solid #7B2CBF;
        background: #10002B;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img::selection, h3::selection {
        color: none;
        background: none;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    h3 {
        text-align: center;
    }

    img {
        width: 80%;
        height: 80%;
    }
}
`