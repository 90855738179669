import { Paginator } from "../../components/Paginator/Paginator";
import { Container, PageDiv } from "./styles";
import { AiOutlineArrowDown } from 'react-icons/ai';
import { Contact } from "../../components/Contact/Contact";
import { FaChevronUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {motion} from 'framer-motion';



export function Me() {
    const navigate = useNavigate();
    return (
        <motion.div
        initial={{
            opacity: 0,
        }}
        animate={{ opacity: 1 }}
        exit={{
            opacity: 0
        }}
        transition={{ duration: 0.6 }}>
            <Paginator title='Me' />
            <PageDiv>
            <FaChevronUp onClick={() => navigate('/skills')} style={{ marginTop: 20 }} />   
            <Container>
                <Contact />
                <section>
                    <div className="col-1">
                        <img src={require('../../assets/pedrocalle.jpeg')} alt="Pedro Calle" />
                    </div>
                    <div className="col-2">
                        <h1>About <span>Pedro Calle.</span></h1>
                        <p>My name is Pedro Calle, I'm a student of computer engineering in PUC-PR, I started in programming in 2021 and I'm always seeking to become the best version of me, both personal and professional ways, always looking forward to upgrade my soft and hard skills. Born in Curitiba, Brazil. English and portuguese fluent speaker. <br/>Design and Programming of this website was made by me.</p>
                        <div className='work-study'>
                            <div className='study'>
                                <div className="rectangle">
                                    <img src={require('../../assets/puc.png')} alt="university" />
                                </div>
                                <h1>Studies</h1>
                                <h2>2021/1</h2>
                                <AiOutlineArrowDown size={64} className='arrow' />
                                <h1>Current</h1>
                            </div>
                            <div className="work">
                                <div className="rectangle">
                                    <img src={require('../../assets/notis.png')} alt="university" />
                                </div>
                                <h1>Work</h1>
                                <h2>2021/2</h2>
                                <AiOutlineArrowDown size={64} className='arrow' />
                                <h1>Current</h1>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            </PageDiv>
        </motion.div>
    )
}