import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    color: white;
  }

  html {
    overflow: hidden; 
  }

  @media (max-width: 1024px) {
    * {
      pointer-events: none;
      overflow: hidden;
    };

    h1, div, img {
      opacity: 0;
    }

    body:before {
      content: 'Mobile coming soon...';
      position: absolute;
      font-style: normal;
      font-weight: 700;
      margin: 50px;
      font-size: 34px;
      line-height: 41px;
      font-family: 'Montserrat';
      color: #9D4EDD;
    }
  }

  h1, h2, h3 {
    font-family: 'Montserrat';
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 41px;
  }

  p {
    font-family: 'Lato';
  }

  body {
    background: #10002B;
  }
`