import { useState } from "react";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Card } from "../Card/Card";
import { motion } from 'framer-motion';
import { ArrowsDiv, Container } from "./styles";

const skills = [
    {
        id: 0,
        title: 'Node.js',
        image: 'https://i.postimg.cc/zXWk6j84/Ellipse-20-1.png',
    },
    {
        id: 1,
        title: 'React.js',
        image: 'https://i.postimg.cc/4xQQNxK5/Ellipse-22.png',
    },
    {
        id: 2,
        title: 'Typescript',
        image: 'https://i.postimg.cc/NjcxcH0F/Ellipse-23.png',
    },
    {
        id: 3,
        title: 'HTML',
        image: 'https://i.postimg.cc/qv165hh9/Ellipse-20-2.png',
    },
    {
        id: 4,
        title: 'CSS',
        image: 'https://i.postimg.cc/wMY1mDZs/Ellipse-20-3.png',
    },
    {
        id: 5,
        title: 'Database',
        image: 'https://i.postimg.cc/ZqkzZjd3/Ellipse-20-4.png',
    },  
    {
        id: 6,
        title: 'Figma',
        image: require('../../assets/figma.png'),
    },  
]

export function Carousel() {
    const [slideData, setSlideData] = useState(skills)
   
    const [id, setId] = useState(0);



    const nextSlide =  () => {
        
       setSlideData(old => [...old, ...old.slice(0,1)].slice(-slideData.length)  )
      
            
           
    }

    const previousSlide = ()  =>{
         setSlideData(old => [...old.slice(-1), ...old].slice(0, slideData.length)  )
        
    }

    return (
        <ArrowsDiv>
            <FaChevronLeft size={34} className='icon' onClick={() => previousSlide()} />
            <Container >
                {slideData.slice(0,3).map((skill, index) => <Card data={skill}  key={index} />)}
            </Container>
            <FaChevronRight size={34} className='icon' onClick={() => nextSlide()} />
        </ArrowsDiv>
    )
}