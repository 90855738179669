import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { useNavigate } from "react-router-dom"
import { Container, PageDiv } from "./styles"
import { motion } from "framer-motion"; import { Carousel } from "../../components/Carrousel/Carousel";
import { Paginator } from "../../components/Paginator/Paginator";
import { Contact } from "../../components/Contact/Contact";
;



export function Skills() {
    const navigate = useNavigate();
    return (
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{ opacity: 1 }}
            exit={{
                opacity: 0
            }}
            transition={{ duration: 0.6 }}
        >
            <Paginator title='Skills'/>
            <PageDiv>
            <Contact />
                <FaChevronUp onClick={() => navigate('/works')} style={{ marginTop: 20 }} />
                <Container>
                    <h1>
                        My <span>Skills</span>
                    </h1>
                    <Carousel />
                </Container>
                <FaChevronDown onClick={() => navigate('/me')} />
            </PageDiv>
        </motion.div>
    )
}