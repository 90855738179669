import styled from 'styled-components';

export const Container = styled.div`
    margin: 0 auto;
    height: 100vh;
    width: 80vw;

    .col-1, .col-2 {
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    p, h1 {
        text-align: center;
    }

    img {
        border-radius: 40px;
    }

    p {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    span {
        color: #9D4EDD;
    }

    section {
        display: flex;
    }

    .rectangle {
        height: 120px;
        width: 100px;
        background: white;
        border-radius: 25px;
        margin-bottom: 20px;
    }

    .work-study {
        display: flex;
        margin-top: 15px;
        width: 400px;
    }

    .work, .study {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
    }

    h2 {
        font-weight: 600;
        font-size: 34px;
        line-height: 41px;
    }

    .arrow {
        fill: #7B2CBF;
        margin: 15px 0;
    }
    
`

export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`