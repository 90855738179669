import styled from 'styled-components';

export const Container = styled.div`
  
  position: fixed;
  right: calc(-10vw - 110px);
  top: 50%;
  transform: translateY(-50%);
  height: 100vh;
  display: flex;
  align-items: center;
transition:0.3s;

  .social {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      fill: #3C096C;
      color: #3C096C;
    }
  }

  button {
    height: 100px;
    width: 100px;
    background: #7B2CBF;
    border-radius: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    padding-left: 10px;
    border: none;
    transition: 0.3s;
    position:relative;
    right:-50px;
    cursor: pointer;
transition:0.3s;
z-index:1;
    
    &:hover {
      background: #3C096C;
    }
  }
  
  .contact {
    height: 100vh;
    width: 10vw;
    background: #7B2CBF;
    padding:50px;
    z-index:2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }

  h3 {
    font-size: 18px;
  }
`