import styled from 'styled-components';

export const Container = styled.div`
h1 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 88px;
    margin-bottom: 50px;
    text-align: center;
  }

  span {
    color: #9D4EDD;
  }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90vh;
`


export const PageDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`