import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

interface IProps {
  title: string;
}



export function Paginator({title}: IProps) {

  const navigate = useNavigate();

  return (
    <Container>
        <div className="title">
        <div className={title === 'Home' ? 'active' : 'circle'} onClick={() => navigate('/')}></div>
        <h3>{title === 'Home' ? title : null}</h3>
        </div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="title">
        <div className={title === 'Works' ? 'active' : 'circle'} onClick={() => navigate('/works')}></div>
        <h3>{title === 'Works' ? title : null}</h3>
        </div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="title">
        <div className={title === 'Skills' ? 'active' : 'circle'} onClick={() => navigate('/skills')}></div>
        <h3>{title === 'Skills' ? title : null}</h3>
        </div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="s-circle"></div>
        <div className="title">
        <div className={title === 'Me' ? 'active' : 'circle'} onClick={() => navigate('/me')}></div>
        <h3>{title === 'Me' ? title : null}</h3>
        </div>
    </Container>
  )
}