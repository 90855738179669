import React from 'react';
import { GlobalStyle } from './styles/GlobalStyles';
import MainRoutes from './routes/Routes';
function App() {
  return (
    <>
      <MainRoutes />
      <GlobalStyle/>
    </>
  );
} 

export default App;
