import { useState } from "react";
import { Container } from "./styles";
import { BsChevronLeft } from 'react-icons/bs';
import { BsLinkedin } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { MdEmail } from "react-icons/md";



export function Contact() {
  const [open, setOpen] = useState(false);

  return (
    <Container style={open ? { right: '0px' } : {}}>
      <button onClick={() => setOpen(!open)}>
        <BsChevronLeft size={20} style={open ? { transform: 'rotate(180deg)' } : {}} />
      </button>
      <div className="contact">
          <BsLinkedin className='social' size={60} onClick={() => window.open('https://www.linkedin.com/in/pedro-calle-017349229/')}/>
          <h3 className='social' onClick={() => window.open('https://www.linkedin.com/in/pedro-calle-017349229/')}>Pedro Calle</h3>
          <MdEmail size={60}/>
          <h3 >pedrocalle1601@gmail.com</h3>
          <BsInstagram size={60} className='social' onClick={() => window.open('https://www.instagram.com/pedrocalle_/')}/>
          <h3 className='social' onClick={() => window.open('https://www.instagram.com/pedrocalle_/')}>@pedrocalle</h3>
      </div>
    </Container>
  )
}